.ant-upload-list-picture .ant-upload-list-item-name {
    display: none;
}

.centered {
    text-align: center;
}


.contractProcess .ant-upload.ant-upload-drag{
    height: 30vh;
    margin-top: 1rem;
    top: 50%;
}
