.add-item-required::before {
    color: #ff4d4f;
    content: '*';
    display: inline-block;
    font-family: SimSun, sans-serif;
    font-size: 14px;
    line-height: 1;
    margin-right: 4px;
}

.ant-upload.ant-upload-drag{
    height: 70vh;
    margin-top: 1rem;
    top: 50%;
}

.ant-upload-list-picture .ant-upload-list-item {
    height: auto;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail {
    height: auto;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
    min-width: 15rem;
    height: auto;
}

.ant-upload-list-item-card-actions {
    position: absolute !important;
}

.form-container {
    background-color: #ffffff;
    padding: 1.5rem 2rem;
    width: 100%;
}

.guttered-row {
    padding: 0 0.5rem 0.5rem 0;
}

.inner-content {
    padding: 1rem 0.5rem;
}

.inner-sider {
    background: #fff !important;
    border: solid 1px #999;
    padding: 0.5rem;
}

.notice {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
}

.thumbnail-img {
    max-height: 5rem;
}
