.ant-badge-status-dot {
    display: none;
}

.ant-badge-status-text {
    color: black;
    font-size: 0.75rem;
}

.ant-picker-calendar-date-content {
    overflow-x: hidden;
    width: 100%;
}

.ant-ribbon.ant-ribbon-placement-end {
    max-width: 100%;
    right: 0;
}

.ant-ribbon-text {
    max-width: 99%;
    display: block;
    font-size: 0.7rem;
    text-overflow: ellipsis;
    overflow: hidden;
}

.events {
  margin: 0;
  padding: 0;
  /* list-style: none; */
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}


.thumbnail-img {
    max-width: 10rem;
}
